import React from 'react';

import Capability from './Capability';

const CapabilityList = () => {
  const CAPABILITIES = [
    {
      heading: 'Multi-Family Syndication',
      description: 'We find exciting B & C class properties that are have a value add opportunity, which translates into strong gains for our investors.',
      iconClass: 'fa-building',
    },
    {
      heading: 'Single Family Rental Purchase',
      description: 'We source single family residences that make sense as a rental property and we guide you in determining what makes a good single family rental.',
      iconClass: 'fa-home',
    },
    {
      heading: 'Single Family Property Management',
      description: 'Being licensed real estate professionals, we offer single family rental property management taking the headache off of you.',
      iconClass: 'fa-home',
    },
    {
      heading: 'Investment Advisory Services',
      description: 'We are available to advise you on deals that you are analyzing. We can help you underwrite large multi-family deals down to single unit homes.',
      iconClass: 'fa-building',
    },
    // {
    //   heading: 'Corem ipsum amet',
    //   description: 'Phasellus convallis elit id ullam corper amet et pulvinar. Duis aliquam turpis mauris, sed ultricies erat dapibus.',
    //   iconClass: 'fa-chain',
    // },
    // {
    //   heading: 'Norem ipsum amet',
    //   description: 'Phasellus convallis elit id ullam corper amet et pulvinar. Duis aliquam turpis mauris, sed ultricies erat dapibus.',
    //   iconClass: 'fa-diamond',
    // },
  ]

  const [capabilities] = React.useState(CAPABILITIES);

  return (
    <>
      {capabilities.map((capability) => <Capability key={capability.heading} {...capability} />)}
    </>
  )
}

export default CapabilityList;
