import React from "react";

const ContactForm = () => {

  return (
    <form 
      action="https://formspree.io/mwkrklrk"
      method="POST"
      id='neem-contact'
    >
      <div className="fields">
        {/* <input type="text" name="_gotcha" style={{display: 'none'}} /> */}
        <div className="field half">
          <label htmlFor="name">Your name:</label>
          <input type="text" name="name" />
        </div>
        <div className="field half">
          <label htmlFor="email">Your email:</label>
          <input type="email" name="email" />
        </div>
        <div className="field">
          <label htmlFor="message">Your message:</label>
          <textarea type="text" name="message" rows="5"/>
        </div>
      </div>
      <div>
        <button type="submit" >Send</button>
      </div>
    </form>
  )
}

export default ContactForm;
