import React from 'react';

import ContactForm from './ContactForm';
// import ContactInformation from './ContactInformation';

const Contact = () =>
  <section id="three" className="wrapper style1 fade-up">
    <div className="inner">
      <h2>Get in touch</h2>
      <p>
        Please use the form below to send us a message.
      </p>
      {/* className="split style1" */}
      <div >
        <ContactForm />
        {/* <ContactInformation /> */}
      </div>
    </div>
  </section>

export default Contact;
